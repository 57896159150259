<template>
    <div class="c-detail">
        <van-nav-bar left-arrow @click-left="onClickLeft" class="nav-style" title="今日结算详情" fixed placeholder safe-area-inset-top />
        <DetailBox v-if='showDetail == "other"' :dataJson='dataJson' :nameVal='nameVal'></DetailBox>
        <DetailBoxCourt v-else-if='showDetail == "court"' :dataJson='dataJson'></DetailBoxCourt>
        <DetailBoxSignature v-else-if='showDetail == "signature"' :dataJson='dataJson'></DetailBoxSignature>
    </div>
</template>

<script>
import { Toast ,NavBar } from 'vant';
import DetailBox from '@/components/DetailBox'
import DetailBoxCourt from '@/components/DetailBoxCourt'
import DetailBoxSignature from '@/components/DetailBoxSignature'
export default {
    name:"detail",
    data(){
        return{
            deptCode:this.$route.query.deptCode,
            dataJson:{},
            showDetail:1,
            nameVal:1
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        getDept(){
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            this.$get(`/finance/api/fee/todaySettlementRecord`).then(res => {
                if (res.code === '200') {
                    this.list = res.data
                    this.list.forEach(item => {
                        if(item.deptCode == this.deptCode){
                            console.info(item)
                            if(item.feeType == '30060002') this.nameVal = 1
                            else this.nameVal = 2

                            if(item.feeType == '30060001') {
                                this.showDetail = "court"
                                item.serviceTime = this.$common.CodeValue('PLATFORM_SERVICE_TIME', item.serviceTime);
                            }
                            else if(item.feeType == '30060006') this.showDetail = "signature"
                            else{ 
                                this.showDetail = "other"
                            }
                            
                            this.dataJson = item
                        }
                    })
                    Toast.clear();
                }
            })
        }
    },
    mounted() {
        this.getDept() 
        
    },
    components:{
        [Toast.name]: Toast,
        [NavBar.name]: NavBar,
        DetailBox,
        DetailBoxCourt,
        DetailBoxSignature
    }
}
</script>

<style lang="scss" scoped>
    .c-detail{
        background: #F4F4F4;
        height: 100vh;
    }
</style>